import React, { useEffect, useState } from 'react';
import {
  ATTENDEE_STATUS,
  CHECK_IN_STATUS,
  teamBookingDetailsButtons as BUTTONS,
} from '../../../../../constants';
import { useUser } from '../../../../../api/queries';
import { LineWrapper, StatusMessage } from '../../../Booking/Card/styled';
import { useCheckInStatus, useConfig } from '../../../../../utils';
import { Accept, Decline, Delete, StartCheckIn } from './Buttons';
import { PersonId, TeamBookingDetails } from '@engage-shared/api';
import { getTeamBookingDetailsStatus } from '@engage-shared/utils';

type TeamBookingButtonsBarProps = {
  booking: TeamBookingDetails;
  attendeeId?: PersonId;
  onBack: () => void;
  onShowLocation?: () => void;
};
export const TeamBookingButtonsBar = ({
  booking,
  attendeeId,
  onBack,
  onShowLocation,
}: TeamBookingButtonsBarProps) => {
  const { data: user } = useUser();
  const userId = user?.id;

  const {
    meetingId,
    startDate,
    endDate,
    attendees,
    location: { buildingId },
  } = booking;

  const [bookingStatus, setBookingStatus] = useState<ATTENDEE_STATUS>(ATTENDEE_STATUS.NOT_INVITED);

  useEffect(() => {
    const currentAttendee = attendees?.find(attendee => attendee.id === userId);
    setBookingStatus(currentAttendee?.status ?? ATTENDEE_STATUS.NOT_INVITED);
  }, [attendees, userId]);

  // User can update invitation's status (accept/decline/check-in/check-out) only if it's his booking item.
  // When opened from agenda the attendeeId is available, and it checks if the selected item is for current user, not booked for other.
  // When deep linked ensure that user is an attendee.
  const canUpdateStatus = attendeeId
    ? userId === attendeeId
    : bookingStatus !== ATTENDEE_STATUS.NOT_INVITED;

  const isBookedForOther = attendeeId ? userId !== attendeeId : !booking.isOrganizer;

  const config = useConfig(buildingId);

  const teamConfig = {
    enableDeskCheckIn: config.enableDeskCheckIn,
    deskCheckInOpenWindow: config.deskCheckInOpenWindow,
    deskCheckInWindowDuration: config.deskCheckInWindowDuration,
    allDayReservationCheckInStartTime: config.allDayReservationCheckInStartTime,
    enableSpaceCheckIn: config.enableSpaceCheckin,
  };

  const buttonStatuses = getTeamBookingDetailsStatus({
    booking,
    config: teamConfig,
    isBookedForOther,
    canUpdateStatus,
    bookingStatus,
  });
  const { DELETE, CHECK_IN, ACCEPT, DECLINE } = BUTTONS;

  // TODO: Team types are not compatible with useCheckInStatus
  // @ts-ignore
  const statusObj = useCheckInStatus({ booking });
  const showCheckInMessage = !!statusObj.statusMessage && !isBookedForOther;

  return (
    <>
      <LineWrapper>
        <Delete status={buttonStatuses[DELETE]} meetingId={meetingId} />

        <Accept
          status={buttonStatuses[ACCEPT]}
          meetingId={meetingId}
          setBookingStatus={setBookingStatus}
        />

        <Decline
          status={buttonStatuses[DECLINE]}
          meetingId={meetingId}
          setBookingStatus={setBookingStatus}
          onBack={onBack}
        />

        <StartCheckIn
          onPress={onShowLocation}
          status={buttonStatuses[CHECK_IN]}
          meetingId={meetingId}
          startDate={startDate}
          endDate={endDate}
        />
      </LineWrapper>

      {showCheckInMessage && (
        <LineWrapper data-testid="checkInMessage">
          <StatusMessage $isClosed={statusObj.status === CHECK_IN_STATUS.CHECK_IN_CLOSED}>
            {statusObj.statusMessage}
          </StatusMessage>
        </LineWrapper>
      )}
    </>
  );
};
