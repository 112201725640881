interface Color {
  color: string;
  opacity?: number;
}

interface Stroke extends Color {
  width: number;
}

export interface ThemeItem {
  color: string;
  opacity?: number;
  stroke?: Stroke;
}

export interface PointSizes {
  important: number;
  unImportant: number;
}

export const ThemeKey = {
  background: 'background',
  wall: 'wall',
  focusedSpace: 'focusedSpace',
  availableSpace: 'availableSpace',
  unAvailableSpace: 'unAvailableSpace',
  focusedUnAvailableSpace: 'focusedUnAvailableSpace',
  focusedTemporaryUnAvailableSpace: 'focusedTemporaryUnAvailableSpace',
  reservableSVLiveSpace: 'reservableSVLiveSpace',
  unImportantSpace: 'unImportantSpace',
  distanceMarker: 'distanceMarker',
  teamReservationsSelected: 'teamReservationsSelected',
  pointSizes: 'pointSizes',
} as const;

export type Theme = {
  [ThemeKey.background]: ThemeItem;
  [ThemeKey.wall]: ThemeItem;
  [ThemeKey.focusedSpace]: ThemeItem;
  [ThemeKey.availableSpace]: ThemeItem;
  [ThemeKey.unAvailableSpace]: ThemeItem;
  [ThemeKey.focusedUnAvailableSpace]: ThemeItem;
  [ThemeKey.reservableSVLiveSpace]: ThemeItem;
  [ThemeKey.focusedTemporaryUnAvailableSpace]: ThemeItem;
  [ThemeKey.unImportantSpace]: ThemeItem;
  [ThemeKey.distanceMarker]: ThemeItem;
  [ThemeKey.teamReservationsSelected]: ThemeItem;
  [ThemeKey.pointSizes]: PointSizes;
};

export type ThemeKey = keyof Theme;
