import { getCustomLabelsForFloor, getKey, getLabelTopLeftOffsets } from '../utils';
import { Label } from '../../../components';
import * as React from 'react';
import { DrawableFloorMarker } from '@engage-shared/api/floors/interfaces';

interface CustomLabelsProps {
  drawableMarkers: DrawableFloorMarker[];
}

const defaultCustomLabelOptions = {
  labelSize: 55,
  maxLabelChars: 30,
  customStyle: {
    height: 20,
    width: 15,
    additionalOffsetY: 4,
  },
};

export const CustomLabels = ({ drawableMarkers }: CustomLabelsProps) => {
  const labels = getCustomLabelsForFloor(drawableMarkers);

  return (
    <>
      {labels.flatMap((label, labelIndex) =>
        label.lines.map((_, lineIndex) => {
          const { xOff, yOff } = getLabelTopLeftOffsets(label.angle);

          return (
            <Label
              key={getKey(label.id, lineIndex)}
              label={label}
              lineIndex={lineIndex}
              labelSize={defaultCustomLabelOptions.labelSize}
              maxLabelChars={defaultCustomLabelOptions.maxLabelChars}
              offsetY={yOff}
              offsetX={xOff}
              customStyle={defaultCustomLabelOptions.customStyle}
              angle={label.angle}
            />
          );
        }),
      )}
    </>
  );
};
