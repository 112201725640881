import * as React from 'react';

import { Theme, ThemeItem, ThemeKey } from './types';
import { defaultGlobalTheme, GlobalTheme } from './globalTheme';

const ACCENT_COLOR = '#6893EE';
const DARK_HIGHLIGHT = '#141922';

const defaultConfig: Theme = {
  background: {
    color: '#FFFFFF',
  },
  wall: {
    color: '#C5CCD9',
  },
  focusedSpace: {
    color: ACCENT_COLOR,
    opacity: 0.7,
    stroke: {
      color: ACCENT_COLOR,
      opacity: 1,
      width: 7,
    },
  },
  availableSpace: {
    color: '#2CA94C',
    opacity: 0.5,
  },
  unAvailableSpace: {
    color: '#6D7483',
    opacity: 0.5,
  },
  reservableSVLiveSpace:{
    color: '#102D76',
    opacity: 0.5,
  },
  focusedUnAvailableSpace: {
    color: '#102D76',
    opacity: 0.5,
  },
  focusedTemporaryUnAvailableSpace: {
    color: '#B89A00',
    opacity: 0.5,
  },
  unImportantSpace: {
    color: '#6D7483',
    opacity: 0.5,
  },
  distanceMarker: {
    color: '#0b8035',
    opacity: 1,
    stroke: {
      color: 'none',
      width: 0,
    },
  },
  teamReservationsSelected: {
    color: ACCENT_COLOR,
    opacity: 1,
    stroke: {
      color: ACCENT_COLOR,
      opacity: 1,
      width: 7,
    },
  },
  pointSizes: {
    important: 50,
    unImportant: 20,
  },
};

const ThemeManager = {
  config: defaultConfig,
  globalTheme: defaultGlobalTheme,

  setTheme(theme: GlobalTheme) {
    const lastConfig = this.config;

    const { floorplanTheme } = theme;

    this.config = { ...defaultConfig, ...floorplanTheme };
    this.globalTheme = theme;

    if (!this.config.pointSizes) {
      this.config.pointSizes = lastConfig.pointSizes;
    }
    if (!this.config.distanceMarker) {
      this.config.distanceMarker = lastConfig.distanceMarker;
    }
  },

  getGlobalTheme() {
    return this.globalTheme;
  },

  getThemeObject(key: ThemeKey) {
    const themeObj = this.config[key];

    if (!themeObj) {
      console.warn('No object in theme with key: ', key);
      return {} as ThemeItem;
    }

    return themeObj;
  },

  getStyle(key: ThemeKey) {
    const themeObj = this.config[key] as ThemeItem;

    if (!themeObj) {
      console.warn('No object in theme with key: ', key);
      return {};
    }
    const style = {
      fill: themeObj.color,
    } as React.CSSProperties;

    if (themeObj.opacity !== undefined) {
      style.fillOpacity = themeObj.opacity;
    } else {
      style.fillOpacity = 1;
    }

    if (themeObj.stroke !== undefined) {
      style.stroke = themeObj.stroke.color;

      if (themeObj.stroke.opacity !== undefined) {
        style.strokeOpacity = themeObj.stroke.opacity;
      }

      if (themeObj.stroke.width !== undefined) {
        style.strokeWidth = themeObj.stroke.width;
      }
    } else {
      style.stroke = 'none';
      style.strokeWidth = 0;
    }

    if (key === 'focusedSpace') {
      style.fill = ACCENT_COLOR;
      style.fillOpacity = '0.7';
      style.stroke = 'white';
    }

    if (key === 'teamReservationsSelected') {
      style.fill = ACCENT_COLOR;
      style.fillOpacity = 1;
      style.stroke = 'white';
      style.strokeOpacity = 1;
      style.strokeWidth = 7;
    }

    return style;
  },
};

export default ThemeManager;
