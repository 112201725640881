import { ElementsProps, Label as LabelType, LabelOptions } from '../../../types';
import { Space } from '@floorplan/api';
import { getKey, getLabelOffsetY, getLabelsForSpaces } from '../utils';
import { Label } from '../../../components';
import * as React from 'react';
import { defaultFloorplanOptions } from '../../../constants';
import { useTranslation } from 'react-i18next';

interface LabelsProps extends ElementsProps {
  spaces: Space[];
  labelOptions?: LabelOptions;
  startTime?: string;
}
export const Labels = ({
  spaces,
  labelOptions = defaultFloorplanOptions.labelOptions,
  startTime,
}: LabelsProps) => {
  const { t } = useTranslation();

  const defaultLabelName = t('Incognito User');
  const showSpaceNameLabels =
    labelOptions?.showSpaceNameLabels || defaultFloorplanOptions.labelOptions?.showSpaceNameLabels;
  const showOccupantLabels =
    labelOptions?.showOccupantLabels || defaultFloorplanOptions.labelOptions?.showOccupantLabels;
  const showSpaceTypeLabels =
    labelOptions?.showSpaceTypeLabels || defaultFloorplanOptions.labelOptions?.showSpaceTypeLabels;
  const labelSize =
    labelOptions?.labelFontSize || defaultFloorplanOptions.labelOptions?.labelFontSize;

  const labels = getLabelsForSpaces({
    spaces,
    includeName: showSpaceNameLabels,
    includeOccupants: showOccupantLabels,
    includeSpaceType: showSpaceTypeLabels,
    startTime: startTime,
    defaultLabelName,
  });

  const createLabel = (label: LabelType, index: number) => {
    const offsetY = getLabelOffsetY(label, index, labelSize);

    return (
      <Label
        label={label}
        lineIndex={index}
        labelSize={labelSize}
        maxLabelChars={30}
        offsetY={offsetY}
        key={getKey(label.id, index)}
      />
    );
  };

  return <>{labels.map(label => label.lines.map((_, index) => createLabel(label, index)))}</>;
};
