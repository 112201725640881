import { isTimeNow, Space } from '@floorplan/api';
import { Label, LabelLine } from '../../types';
import { UNKNOWN_PERSON } from '@engage-web/constants';
import { DrawableFloorMarker } from '@engage-shared/api/floors/interfaces';

const removeOccupantDuplicates = (occupants: { name: string }[]) => {
  const names = occupants.map(item => item.name);
  return occupants.filter((item, index) => !names.includes(item.name, index + 1));
};

export const getKey = (spaceId: string | number, shapeIndex: number) =>
  `s.${spaceId}.${shapeIndex}`;

export const getLabelId = (spaceId: number, shapeIndex: number) => `${spaceId}.${shapeIndex}`;

type GetLabelsForSpace = (props: {
  space: Space;
  includeName?: boolean;
  includeOccupants?: boolean;
  includeSpaceType?: boolean;
  startTime?: string;
  defaultLabelName: string;
}) => Label[];

export const getLabelsForSpace: GetLabelsForSpace = ({
  space,
  includeName = true,
  includeOccupants = true,
  includeSpaceType = false,
  startTime,
  defaultLabelName,
}) =>
  space.shapes.map((shape, i) => {
    const lines: LabelLine[] = [];

    const isNow = !!startTime && isTimeNow(new Date(startTime));

    const occupantOrigins =
      space.reservations || (isNow && space.wiredSVLivePeople) || space.homeLocations;

    if (includeOccupants && occupantOrigins && occupantOrigins[0]?.name) {
      const uniqueOccupants = removeOccupantDuplicates(occupantOrigins);
      for (const occupant of uniqueOccupants) {
        const name = occupant.name !== UNKNOWN_PERSON ? occupant.name : defaultLabelName;
        lines.push({ text: name });
      }
      // while testing found duplicate occupants in reservations array for some desks
      // we do not want to show duplicate lines
      // if we have occupant on space return immediately, we don't want to show space name or type labels
      return {
        id: getLabelId(space.id, i),
        coordinate: shape.labelPoint,
        lines,
      };
    }

    if (includeSpaceType) {
      lines.push({ text: space.description });
    }
    if (includeName && space.allowInteraction) {
      //use preferredName when it exists (deskName will contain the preferredName)
      const name = `${space.deskName}` !== `${space.id}` ? space.deskName : space.name;

      if (name) {
        lines.push({ text: name });
      } else if (!includeSpaceType) {
        lines.push({ text: space.description });
      }
    }

    return {
      id: getLabelId(space.id, i),
      coordinate: shape.labelPoint,
      lines,
    };
  });

type GetLabelsForSpacesProps = {
  spaces: Space[];
  includeName?: boolean;
  includeOccupants?: boolean;
  includeSpaceType?: boolean;
  startTime?: string;
  defaultLabelName: string;
};
export const getLabelsForSpaces = ({
  spaces = [],
  includeName = true,
  includeOccupants = true,
  includeSpaceType = false,
  startTime,
  defaultLabelName,
}: GetLabelsForSpacesProps) =>
  spaces
    .filter(
      space => space.shapes && space.name !== 'Traditional Workspace' && space.name !== 'Workspace',
    )
    .map(space =>
      getLabelsForSpace({
        space,
        includeName,
        includeOccupants,
        includeSpaceType,
        startTime,
        defaultLabelName,
      }),
    )
    .reduce((prev, next) => prev.concat(next), []);

export function getLabelOffsetY(label: Label, index: number, labelSize: number) {
  const lineHeight = 26 - (16 - labelSize);
  return index * lineHeight - ((label.lines.length - 1) * lineHeight) / 2;
}

export const getCustomLabelsForFloor = (drawableMarkers: DrawableFloorMarker[]) =>
  drawableMarkers
    .filter(({ category }) => category === 'Label')
    .map(({ id, xCoordinate: x, yCoordinate: y, type: text, angle }) => ({
      id: id?.toString(),
      coordinate: { x, y },
      lines: [{ text }],
      angle,
    }));

type Offset = {
  xOff: number;
  yOff: number;
};

export const getLabelTopLeftOffsets = (angle: number) => {
  const offsets: { [key: number]: Offset } = {
    180: { xOff: -90, yOff: -60 },
    90: { xOff: 20, yOff: -160 },
    270: { xOff: -20, yOff: 160 },
  };
  return offsets[angle] || { xOff: 90, yOff: 60 };
};
