import { FilteredSpaces, PresenceEvent, Space } from '../types';

export const SPACE_TYPE_IDS = {
  ZONE: 78,
  LIFTS: 11026,
  FIRE_STAIRS: 11027,
  TOILET_WASHROOM: 11028,
};

export const SENSOR_STATUS = {
  OCCUPIED: 'occupied',
  VACANT: 'vacant',
  OFFLINE: 'offline',
};

export const PRESENCE_STATUS = {
  ACTIVE: 'active',
  AWAY: 'away',
  REMOTE: 'remote',
  OFFLINE: 'offline',
};

const FilterSpaceType = {
  ROOMS: 'rooms',
  OCCUPANT_ROOM_POINTS: 'occupantRoomPoints',
  TEMPORARY_OCCUPANT_ROOMS: 'temporaryOccupantRooms',
  OCCUPANT_POINTS: 'occupantPoints',
  NON_OCCUPANT_POINTS: 'nonOccupantPoints',
  TEMPORARY_OCCUPANT_POINTS: 'temporaryOccupantPoints',
  ZONES: 'zones',
};

// TODO: get this value from tenant config when that will be available
const showSensorInfo = true;

// TODO refactor file since was just copy/paste
const createOrPush = (
  obj: any,
  key: (typeof FilterSpaceType)[keyof typeof FilterSpaceType] | number,
  item: Space,
) => {
  if (Array.isArray(obj[key])) {
    obj[key].push(item);
  } else {
    // eslint-disable-next-line no-param-reassign
    obj[key] = [item];
  }
};

export const roundTimeToInterval = (date: Date, direction: 'up' | 'down') => {
  const coeff = 1000 * 60 * 15; // seconds

  switch (direction) {
    case 'up':
      return new Date(Math.ceil(date.getTime() / coeff) * coeff);

    case 'down':
      return new Date(Math.floor(date.getTime() / coeff) * coeff);

    default:
      return new Date(Math.round(date.getTime() / coeff) * coeff);
  }
};

/**
 * Check if date is in the current interval, meaning 'Now' for timeline.
 */
export const isTimeNow = (date: Date) =>
  !date ||
  (date >= roundTimeToInterval(new Date(), 'down') &&
    date <= roundTimeToInterval(new Date(), 'up'));

// TODO get SVLive logic from engage-shared after we have the final implementation
/**
 * Get difference in minutes between two dates
 * @param dt2
 * @param dt1
 * @returns {number}
 */
const differenceInMinutes = (dt2: Date, dt1: Date) => {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
};

/**
 * Retrieves latest present event.
 * @param events
 * @returns {null|*}
 */
export const getLatestPresenceEvent = (events: PresenceEvent[]) => {
  if (!events) return null;

  return events.reduce((lastSavedItem: PresenceEvent | null, currentItem) => {
    if (!lastSavedItem) return currentItem;

    if (
      new Date(currentItem.lastSeenTime).getTime() > new Date(lastSavedItem?.lastSeenTime).getTime()
    ) {
      return currentItem;
    }

    return lastSavedItem;
  }, null);
};
/**
 * Returns true if a space is occupied by presence event plus the present event.
 * @param space
 * @param startTime timeline date
 * @returns {{isOccupied: boolean, presenceEvent: (*|null)}}
 */
export const getSpaceOccupiedByPresenceStatus = (space: Space, startTime?: string) => {
  let isOccupied = false;

  const isNow = !!startTime && isTimeNow(new Date(startTime));

  if (!isNow || !showSensorInfo) return { isOccupied, presenceEvent: null };

  const presenceEvents = space?.presenceEvents;
  const presenceEvent = getLatestPresenceEvent(presenceEvents);
  if (presenceEvent) {
    const { presenceStatus, lastSeenTime } = presenceEvent;
    isOccupied =
      // SVLive data is less than 60 minutes old
      differenceInMinutes(new Date(), new Date(lastSeenTime)) <= 60 &&
      // SVLive presence data set to active, away or remote
      (presenceStatus === PRESENCE_STATUS.ACTIVE ||
        presenceStatus === PRESENCE_STATUS.AWAY ||
        presenceStatus === PRESENCE_STATUS.REMOTE);
  }
  return { isOccupied, presenceEvent };
};

/**
 * Returns true is space is occupied by sensor
 * @param space
 * @param startTime timeline date
 * @returns {boolean}
 */
export const isSpaceOccupiedBySensor = (space: Space, startTime?: string) => {
  const isNow = !!startTime && isTimeNow(new Date(startTime));

  if (!isNow || !showSensorInfo) return false;

  return space?.sensorStatus === SENSOR_STATUS.OCCUPIED;
};

// rules from https://eptura.atlassian.net/browse/EEX-244
const isVacantBySensor = (space: Space, startTime?: string) => {
  const isNow = !!startTime && isTimeNow(new Date(startTime));

  return (
    !space.bookable &&
    !space.available &&
    !space.isReserved &&
    space.sensorStatus === SENSOR_STATUS.VACANT &&
    isNow &&
    showSensorInfo
  );
};

const filterRooms = (filteredSpaces: FilteredSpaces, space: Space, startTime?: string) => {
  if (!space.isDesk) {
    // if room has sensor status vacant, it is not occupied - force available = true
    if (isVacantBySensor(space, startTime) && space.allowInteraction) {
      createOrPush(filteredSpaces, FilterSpaceType.ROOMS, { ...space, available: true });
      return;
    }

    const isNonBookableOccupiedBySensor =
      !space.bookable && !space.available && isSpaceOccupiedBySensor(space, startTime);

    const isBookableOccupiedBySensor =
      space.bookable && space.available && isSpaceOccupiedBySensor(space, startTime);

    // Check if a space is a room
    if (space.shapes[0] && space.spaceTypeId !== SPACE_TYPE_IDS.ZONE) {
      // Check if a room is occupied/reserved - highlight with navy blue
      if (space.isReserved || isNonBookableOccupiedBySensor) {
        createOrPush(filteredSpaces, FilterSpaceType.OCCUPANT_ROOM_POINTS, space);

        // check if an available room is temporary occupied ( sensor data indicating presence) - highlight with orange
      } else if (isBookableOccupiedBySensor) {
        createOrPush(filteredSpaces, FilterSpaceType.TEMPORARY_OCCUPANT_ROOMS, space);

        // highlight with green or grey based on availability
      } else {
        createOrPush(filteredSpaces, FilterSpaceType.ROOMS, space);
      }
    }
  }
};

// this is copy paste from engage-floorplan and can be improved
const filterDesks = (filteredSpaces: FilteredSpaces, space: Space, startTime?: string) => {
  if (space.isDesk) {
    // if desk has sensor status vacant, it is not occupied - force available = true
    if (isVacantBySensor(space, startTime) && space.allowInteraction) {
      createOrPush(filteredSpaces, FilterSpaceType.NON_OCCUPANT_POINTS, {
        ...space,
        available: true,
      });
      return;
    }

    const nonOccupant = space.available && space.bookable;
    // Check if space is an occupant point (meaning a person is there, room or desk)
    const { isOccupied: isSpaceOccupiedByPresenceStatus } = getSpaceOccupiedByPresenceStatus(
      space,
      startTime,
    );

    // fist check SVLive data and second check sensor data, and use them only for current time, not for future dates
    const isOccupied = isSpaceOccupiedByPresenceStatus || isSpaceOccupiedBySensor(space, startTime);

    // Check if an available desk is temporary occupied ( sensor/SVLive data indicating presence) - highlight with orange
    if (nonOccupant && isOccupied) {
      createOrPush(filteredSpaces, FilterSpaceType.TEMPORARY_OCCUPANT_POINTS, space);

      // Check if desk is occupied/reserved - highlight with navy blue
    } else if (!nonOccupant || isOccupied || space.isReserved) {
      createOrPush(filteredSpaces, FilterSpaceType.OCCUPANT_POINTS, space);
    }

    // Check if space is a non Occupant point - available desk
    else if (nonOccupant) {
      createOrPush(filteredSpaces, FilterSpaceType.NON_OCCUPANT_POINTS, space);
    }
  }
};

// this is copy paste from engage-floorplan and can be improved
export const getFilteredSpaces = (spaces: Space[] = [], startTime?: string): FilteredSpaces => {
  const filteredSpaces = {
    zones: [],
    occupantPoints: [],
    nonOccupantPoints: [],
    temporaryOccupantPoints: [],
    rooms: [],
    occupantRoomPoints: [],
    temporaryOccupantRooms: [],
    special: {
      [SPACE_TYPE_IDS.LIFTS]: [],
      [SPACE_TYPE_IDS.TOILET_WASHROOM]: [],
      [SPACE_TYPE_IDS.FIRE_STAIRS]: [],
    },
  } as FilteredSpaces;

  if (!spaces?.length) {
    return filteredSpaces;
  }

  spaces.forEach(space => {
    switch (space.spaceTypeId) {
      case SPACE_TYPE_IDS.LIFTS:
      case SPACE_TYPE_IDS.TOILET_WASHROOM:
      case SPACE_TYPE_IDS.FIRE_STAIRS:
        createOrPush(filteredSpaces.special, space.spaceTypeId, space);
        break;

      case SPACE_TYPE_IDS.ZONE:
        createOrPush(filteredSpaces, FilterSpaceType.ZONES, space);
        break;
      default:
        break;
    }

    const hasSpaceShape = space.shapes && space.shapes[0] && space.shapes[0].isPolygon;
    // this is intentional to fix API mishandling the isDesk attribute
    if (space.isDesk === undefined) {
      // eslint-disable-next-line no-param-reassign
      space.isDesk = !hasSpaceShape;
    }

    // Assign occupant point to shape, so we can deduce coordinates later
    if (space.occupantPoint) {
      // eslint-disable-next-line no-param-reassign
      space.shape = space.occupantPoint[0] ? space.occupantPoint[0] : space.occupantPoint;
    }

    if (hasSpaceShape) {
      // eslint-disable-next-line no-param-reassign,prefer-destructuring
      space.shape = space.shapes[0];
    }

    filterRooms(filteredSpaces, space, startTime);
    filterDesks(filteredSpaces, space, startTime);
  });

  return filteredSpaces;
};

// this is copy paste from engage-floorplan and can be improved
export const getUpdatedSpaces = (prevSpaces: Space[] = [], newSpaces: Space[] = []) => {
  const updatedSpaces = prevSpaces?.length ? [...prevSpaces] : [];

  const updatedSpacesMap = updatedSpaces.map(s => s.id);
  newSpaces.forEach(space => {
    const spaceIndex = updatedSpacesMap.indexOf(space.id);
    if (spaceIndex !== -1) {
      updatedSpaces[spaceIndex] = {
        ...updatedSpaces[spaceIndex],
        ...space,
      };
    }
  });

  return updatedSpaces;
};

type GetMergedSpaceData = {
  allSpaces: Space[];
  allSpacesForRooms: Space[];
  availableSpaces: Space[];
  availableDesks: Space[];
};

export const getMergedSpaceData = ({
  allSpaces = [],
  allSpacesForRooms = [],
  availableSpaces = [],
  availableDesks = [],
}: GetMergedSpaceData) => {
  const updatedSpaces = allSpaces?.length ? [...allSpaces] : [];

  const updatedSpacesMap = updatedSpaces.map(s => s.id);
  availableDesks.forEach(space => {
    const spaceIndex = updatedSpacesMap.indexOf(space.id);
    if (spaceIndex !== -1 && updatedSpaces[spaceIndex].isDesk) {
      updatedSpaces[spaceIndex] = {
        ...updatedSpaces[spaceIndex],
        ...space,
      };
    }
  });

  allSpacesForRooms.forEach(space => {
    const spaceIndex = updatedSpacesMap.indexOf(space.id);
    if (spaceIndex !== -1 && !updatedSpaces[spaceIndex].isDesk) {
      updatedSpaces[spaceIndex] = {
        ...updatedSpaces[spaceIndex],
        ...space,
      };
    }
  });

  availableSpaces.forEach(space => {
    const spaceIndex = updatedSpacesMap.indexOf(space.id);
    if (spaceIndex !== -1 && !updatedSpaces[spaceIndex].isDesk) {
      updatedSpaces[spaceIndex] = {
        ...updatedSpaces[spaceIndex],
        ...space,
      };
    }
  });
  return updatedSpaces;
};
