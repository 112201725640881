import * as React from 'react';
import OpenSeadragon from 'openseadragon';
import { Icon } from '@engage-web/components/base/Icons/Icon';
import { getCoordinateString, getPathString } from '../../utils';
import { DEFAULT_FLOORPLAN_SCALE, doubleClickTime, DEFAULT_CIRCLE_SIZE } from '../../constants';
import { calculateStyle } from './utils';
import { EventInfo } from '../../types';
import { getLatestPresenceEvent } from '../../../../api/src/space';
import {
  getMobileStatusIcon,
  getPresenceStatusIcon,
  getSVLiveFloorPlanIconName,
} from '@engage-shared/utils';
import ClickableShape from './ClickableShape';
import { ShapeProps } from './types';
import { PresenceStatus } from '@engage-shared/constants';

export type IconName = 'away' | 'present' | 'alert-circle' | 'active';
export type IconProps = {
  name: IconName;
  size: number;
  style: { color: string };
};

interface ViewBoxObj {
  [key: string]: string;
}

const viewBoxobj: ViewBoxObj = {
  away: '0 0 108 108',
  'svlive-present': '0 0 29 29',
  warning: '0 0 24 24',
  active: '0 0 108 108',
};

export const Shape = ({
  space,
  shape,
  isPoint = false,
  getPointSize,
  style,
  scale,
  styleFunction,
  onShapeClicked,
  isSelected = false,
}: ShapeProps) => {
  const waitIfDoubleTimeoutRef = React.useRef(-1);
  const selectedString = isSelected ? '-selected' : '';
  const latestPresenceEvent = getLatestPresenceEvent(space?.presenceEvents);

  const shapeStyle = calculateStyle<React.CSSProperties>({
    space,
    shape,
    isPoint,
    style,
    styleFunction,
  });

  const clickHandler = React.useCallback(
    (event: OpenSeadragon.CanvasClickEvent) => {
      if (waitIfDoubleTimeoutRef.current !== -1) {
        clearTimeout(waitIfDoubleTimeoutRef.current);
        waitIfDoubleTimeoutRef.current = -1;
        return;
      }

      waitIfDoubleTimeoutRef.current = setTimeout(() => {
        const eventInfo = {
          originalEvent: event.originalEvent,
          space,
          shape,
        } as EventInfo;
        if (onShapeClicked) {
          onShapeClicked(eventInfo);
        }
        waitIfDoubleTimeoutRef.current = -1;
      }, doubleClickTime) as unknown as number;
    },
    [onShapeClicked, shape, space],
  );

  const isWeb = window.screen.width >= 1280;

  let elementId = `space-${space.id}-desk${selectedString}`;
  if (shape?.holes) {
    elementId = `space-${space.id}-holes`;
  } else if (shape?.isPolygon) {
    elementId = `space-${space.id}-room${selectedString}`;
  }

  const renderElement = () => {
    if (shape.holes) {
      return (
        <path
          id={elementId}
          d={`${getPathString(shape.coordinates)} ${shape.holes
            .map(hole => getPathString(hole))
            .join(' ')} z`}
          style={shapeStyle}
        />
      );
    }

    if (shape.isPolygon) {
      return <polygon id={elementId} points={getCoordinateString(shape)} style={shapeStyle} />;
    }

    const pointSize = getPointSize?.(space) ?? DEFAULT_CIRCLE_SIZE;
    const radius = Math.ceil((pointSize * DEFAULT_FLOORPLAN_SCALE) / scale);

    const renderIcon = (props: IconProps) => <Icon {...props} size={radius / 1.7} />;

    const hasSVLiveData = !!latestPresenceEvent;

    const svLiveFloorPlanIcon = getSVLiveFloorPlanIconName(hasSVLiveData, space);
    const icon = hasSVLiveData
      ? !!svLiveFloorPlanIcon &&
        getPresenceStatusIcon(svLiveFloorPlanIcon as PresenceStatus, renderIcon)
      : null;

    const getViewBoxStyle = (): string => {
      if (isWeb) {
        return '';
      }
      if (svLiveFloorPlanIcon && viewBoxobj[svLiveFloorPlanIcon]) {
        return viewBoxobj[svLiveFloorPlanIcon];
      }
      return '';
    };

    const renderSvgIcon = (): JSX.Element | any => {
      if (isWeb) {
        return icon; // Assuming `icon` is of type `string` or JSX.Element
      }
      return getMobileStatusIcon(svLiveFloorPlanIcon as PresenceStatus);
    };

    if (hasSVLiveData) {
      return (
        <g>
          <circle
            id={elementId}
            data-space-id={`${space.id}`}
            cx={shape.coordinates[0].x}
            cy={shape.coordinates[0].y}
            r={radius}
            style={shapeStyle}
          />
          <svg
            x={shape.coordinates[0].x + 15}
            y={shape.coordinates[0].y + 15}
            width={radius / 1.7}
            height={radius / 1.7}
            viewBox={getViewBoxStyle()}
          >
            {renderSvgIcon()}
          </svg>
        </g>
      );
    }

    return (
      <circle
        id={elementId}
        data-space-id={`${space.id}`}
        cx={shape.coordinates[0].x}
        cy={shape.coordinates[0].y}
        r={radius}
        style={shapeStyle}
      />
    );
  };

  if (!shape) {
    return null;
  }

  return (
    <ClickableShape clickHandler={clickHandler} elementId={elementId}>
      {renderElement()}
    </ClickableShape>
  );
};
