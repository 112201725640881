import { ColorMap, Floorplan, Theme, ThemeColors } from './types';
import { generateInactiveColor, isValidHexColor } from './utils';
import { getLogger } from '@engage-shared/utils/logger';

interface Dict<T> {
  [k: string]: T;
}

function reduceDict<T, S>(
  inputDict: Dict<T>,
  reducerFunction: (currentVal: S, dictItem: T, key: string) => S,
  initialValue: S,
): S {
  let value = initialValue;
  for (const k of Object.keys(inputDict)) {
    const thisVal = inputDict[k];
    value = reducerFunction(value, thisVal, k);
  }
  return value;
}

export const defaultTheme: ThemeColors = {
  color1: '#002E5D',
  color2: '#454B66',
  color3: '#DFE4EA',
  color4: '#EFF2F6',
  color5: '#EDEFF0',
  color6: '#FFFFFF',
  color7: '#F9FAFA',
  color8: '#F5F6F6',
  color9: '#0000FF',
  color10: '#0000FF',
  color11: '#F8403D',
  typo1: '#FFFFFF',
  typo2: '#18224C',
  status1: '#DFE3EA',
  status2: '#0B8A00',
  status3: '#A96600',
  status4: '#963838',
  status5: '#0000FF',
  gray4: '#898E97',
  gray5: '#73738C',
  inactiveColor: '#0000B3',
  blue1: '#6893EE',
  lightBlue: '#00FFD1',
};

export const getFloorplanTheme = ({
  accentColor,
}: {
  accentColor: string;
  color1: string;
}): Floorplan => ({
  background: {
    color: '#FFFFFF',
  },
  wall: {
    color: '#C5CCD9',
  },
  focusedSpace: {
    color: accentColor,
    opacity: 0.7,
    stroke: {
      color: accentColor,
      opacity: 1,
      width: 7,
    },
  },
  availableSpace: {
    color: accentColor,
    opacity: 0.5,
  },
  unAvailableSpace: {
    color: '#6D7483',
    opacity: 0.5,
  },
  reservableSVLiveSpace:{
    color: '#102D76',
    opacity: 0.5,
  },
  focusedUnAvailableSpace: {
    color: '#102D76',
    opacity: 0.5,
  },
  focusedTemporaryUnAvailableSpace: {
    color: '#B89A00',
    opacity: 0.5,
  },
  unImportantSpace: {
    color: '#6D7483',
    opacity: 0.5,
  },
  pointSizes: {
    important: 40,
    unImportant: 20,
  },
});

export const getDefaultTheme = (
  brandColor = '#0000FF',
  floorPlanAvailableColor = '#2CA94C',
  useFloorPlanAvailableColor = false,
): Theme => {
  const theme = { ...defaultTheme };
  // Set the brand color
  if (isValidHexColor(brandColor)) {
    theme.color9 = brandColor;
  } else {
    getLogger().log('Error on defined brandColor');
  }

  const inactiveColor = generateInactiveColor(theme.color9, 0.7);

  const isFloorplanAvailableColorValid = isValidHexColor(floorPlanAvailableColor);

  const floorplanAccentColor =
    useFloorPlanAvailableColor && isFloorplanAvailableColorValid
      ? floorPlanAvailableColor
      : '#2CA94C';

  // Generate the color map for the responsive style hook
  const colors: ThemeColors = { ...theme, inactiveColor };

  const reducer = (themeColors: Dict<string>, color: string, key: string): Dict<string> => {
    const newIndex = '$' + key;
    themeColors[newIndex] = color;
    return themeColors;
  };

  const globalStyles = reduceDict(colors, reducer, {}) as Record<ColorMap, string>;

  return {
    ...colors,
    floorplanTheme: getFloorplanTheme({
      accentColor: floorplanAccentColor,
      color1: theme.color1,
    }),
    globalStyles,
  };
};
