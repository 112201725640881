import styled from 'styled-components';

interface ILogoWrapper {
  $width: string;
  $height: string;
  $fillColor?: string;
}

export const LogoWrapper = styled.div<ILogoWrapper>`
  width: 100%;
  max-width: 175px;
  display: flex;
  align-items: center;
  border-radius: 25px;

  svg {
    width: ${({ $width }) => $width};
    height: ${({ $height }) => $height};
    ${({ $fillColor }) =>
      $fillColor &&
      `
       * {
         fill: ${$fillColor}
       }
    `}
`;

interface ILogoFallback {
  $textColor?: string;
}
export const LogoFallback = styled.span<ILogoFallback>`
  font-size: 22px;
  font-weight: 700;
  ${({ $textColor }) => $textColor && `color: ${$textColor};`};
`;
