import React from 'react';

export const away = () => (
  <>
    <circle cx="54" cy="54" r="54" fill="#FFA800" />
    <path
      d="M48.2666 55.615C48.0938 55.1084 48 54.5651 48 54V24C48 21.2386 50.2386 19 53 19C55.7614 19 58 21.2386 58 24L58 51.9297L74.5355 68.4652C76.4882 70.4178 76.4882 73.5837 74.5355 75.5363C72.5829 77.4889 69.4171 77.4889 67.4645 75.5363L49.4645 57.5363C48.9047 56.9765 48.5054 56.317 48.2666 55.615Z"
      fill="currentColor"
    />
  </>
);

export const present = () => (
  <>
    <path
      fill="#2CA94C"
      d="M15,28.5C7.6,28.5,1.5,22.4,1.5,15S7.6,1.5,15,1.5S28.5,7.6,28.5,15S22.4,28.5,15,28.5z"
    />
    <path
      fill="#fff"
      d="M15,2c7.2,0,13,5.8,13,13s-5.8,13-13,13S2,22.2,2,15S7.8,2,15,2 M15,1C7.3,1,1,7.3,1,15s6.3,14,14,14 s14-6.3,14-14S22.7,1,15,1L15,1z"
    />
    <path fill="#fff" d="M12.8 20.8L7.8 15.7 9.2 14.3 12.8 17.9 21.8 8.9 23.2 10.4z" />
  </>
);

export const alertCircle = () => (
  <>
    <rect width="100%" height="100%" fill="white" rx="20" />
    <path
      d="M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
      fill="#DA7D05"
    />
  </>
);

export const active = () => (
  <>
    <circle cx="54" cy="54" r="54" fill="#11D600" />
    <path
      d="M46.9222 70.9289C45.6448 70.9272 44.368 70.4391 43.3934 69.4645L29.4645 55.5355C27.5118 53.5829 27.5118 50.4171 29.4645 48.4645C31.4171 46.5118 34.5829 46.5118 36.5355 48.4645L46.9297 58.8586L71.3238 34.4645C73.2765 32.5118 76.4423 32.5118 78.3949 34.4645C80.3475 36.4171 80.3475 39.5829 78.3949 41.5355L50.466 69.4645C49.4876 70.4429 48.2045 70.931 46.9222 70.9289Z"
      fill="currentColor"
    />
  </>
);
