import { Nullable } from '@engage-shared/utils/types';
import { SENSOR_STATUS } from '@engage-shared/constants/space';
import { PresenceStatus } from '@engage-shared/constants/person';
import { Space } from '@floorplan/api';
import {
  active,
  alertCircle,
  away,
  present,
} from 'libs/floorplan/base/src/components/Icon/availabilityIcon';

export type IconName = 'away' | 'present' | 'alert-circle' | 'active';
export type IconProps = {
  name: IconName;
  size: number;
  style: { color: string };
};
export type RenderIcon = (props: IconProps) => Nullable<JSX.Element>;

/**
 * returns icon based on status and mapping passed
 * @param status
 * @param statusToIconMapping
 * @param renderIcon - render icon component (web or mobile versions)
 */
const getStatusIcon = <T extends string>(
  status: T,
  statusToIconMapping: { [P in T]: Nullable<IconName> },
  renderIcon: RenderIcon,
): Nullable<JSX.Element> =>
  statusToIconMapping[status]
    ? renderIcon({
        name: statusToIconMapping[status]!,
        size: 22,
        style: { color: '#fff' },
      })
    : null;

export const getMobileStatusIcon = (presenceStatus: PresenceStatus) => {
  if (presenceStatus == PresenceStatus.ACTIVE) {
    return active();
  } else if (presenceStatus == PresenceStatus.SVLIVE_PRESENT) {
    return present();
  } else if (presenceStatus == PresenceStatus.AWAY) {
    return away();
  } else if (presenceStatus == PresenceStatus.WARNING) {
    return alertCircle();
  }
};

const SensorStatusToIcon: { [P in SENSOR_STATUS]: Nullable<IconName> } = {
  [SENSOR_STATUS.OFFLINE]: null,
  [SENSOR_STATUS.VACANT]: 'away',
  [SENSOR_STATUS.OCCUPIED]: 'present',
};

export const getSensorStatusIcon = (
  sensorStatus: SENSOR_STATUS,
  renderIcon: RenderIcon,
): Nullable<JSX.Element> => getStatusIcon(sensorStatus, SensorStatusToIcon, renderIcon);

const PresenceStatusToIcon: { [P in PresenceStatus]: Nullable<IconName> } = {
  [PresenceStatus.OFFLINE]: null,
  [PresenceStatus.ACTIVE]: 'present',
  [PresenceStatus.WIFI]: 'present',
  [PresenceStatus.AWAY]: 'away',
  [PresenceStatus.REMOTE]: 'away',
  [PresenceStatus.WARNING]: 'alert-circle',
  [PresenceStatus.SVLIVE_PRESENT]: 'active',
};

export const getSVLiveFloorPlanIconName = (hasSVLiveData: boolean, space: Space) => {
  if (hasSVLiveData && space.wiredSVLivePeople && space.reservations) {
    if (space.wiredSVLivePeople[0].name === space.reservations[0].name) {
      return 'svlive-present';
    }
  }
  return 'warning';
};

export const getPresenceStatusIcon = (
  presenceStatus: PresenceStatus,
  renderIcon: RenderIcon,
): Nullable<JSX.Element> => getStatusIcon(presenceStatus, PresenceStatusToIcon, renderIcon);
